import React from "react";
import { graphql, Link } from 'gatsby'
import Seo from '../components/SEO/SEO'
import ReactPlayer from 'react-player'

import Image from "gatsby-plugin-sanity-image";

// import Swiper core and required modules
import { Navigation, A11y, Lazy } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';

export const query = graphql`
  query ProjectTemplateQuery($id: String!) {
    project: sanityProject(id: { eq: $id }) {
      id
      description
      title
      photographerUrl
      photographer
      location
      architects
      architectsUrl
      completed
      _rawPreviewImage
      slideshow {
        images {
          __typename
          ... on SanityImage {
            ...Image
          }
          ... on SanityVideo {
            _id
            videoFile {
              asset {
                  url
                }
            }
          }
        }
      }
    }
  }
`

const ProjectTemplate = props => {
  const { data } = props
  const project = data && data.project

  const ProjectSlide = ({ item }) => {
    if (item.__typename === 'SanityImage') {
      return (
        <Image
          {...item}
          width={1694}
          height={895}
          className="swiper-lazy"
          alt=" "
        />
      );
    } else if (item.__typename === 'SanityVideo') {
      return (
        <ReactPlayer 
          url={item.videoFile.asset.url}
          width="100%"
          height="100%"
          className="aspect-[1694/895] relative video-wrapper"
          playing
          playsinline={true}
          loop
          muted
        />
      );
    } else {
      return null;
    }
  };

  return (
    <>
      {project && (
        <Seo
          title={project.title || 'Untitled'}
          description={project.description}
          image={project._rawPreviewImage}
        />
      )}
      <div className="flex flex-col container min-h-[100vh]">
        <div className="my-auto py-[5vh] uppercase text-[15px] tracking-[0.3em]">
          <div className="flex flex-row justify-end mb-2">
            <Link className="close" to="/projects">Close</Link>
          </div>
          {project.slideshow &&
            <Swiper
              className="group"
              modules={[Navigation, A11y, Lazy]}
              navigation={true}
              slidesPerView={1}
              effect={'fade'}
              lazy={true}
              loop={true}
              speed={700}
            >
              {project.slideshow.images.map((item, index) => (
                <SwiperSlide>
                  <ProjectSlide item={item} key={index} />
                </SwiperSlide>
              ))}
            </Swiper>
          }
          <div className="flex flex-col 2xl:flex-row gap-4 2xl:gap-8 mt-6 2xl:mt-3">
            {project.title && 
              <h1>{project.title}</h1>
            }
            {project.location && 
              <h2>{project.location}</h2>
            }
            {project.completed && 
              <h2>Completed {project.completed}</h2>
            }
            {project && project.architectsUrl ? (
              <h2>
                <a className="border-b-2 border-transparent transition-all ease-in-out duration-200 hover:border-current" target="_blank" rel="noreferrer" href={project.architectsUrl}>
                {project.architects}
                </a>
              </h2>
            ) : (
              <h2>
                {project.architects}
              </h2>
            )}
            {project.photographerUrl ? (
                <h2 className="2xl:ml-auto">
                    Photos:
                    <a
                    className="ml-2 lg:ml-4 border-b-2 border-transparent transition-all ease-in-out duration-200 hover:border-current"
                    target="_blank"
                    rel="noreferrer"
                    href={project.photographerUrl}
                    >
                    {project.photographer}
                    </a>
                </h2>
            ) : project.photographer ? (
                <h2 className="2xl:ml-auto">
                    Photos:<span className="ml-2 lg:ml-4 inline-block">{project.photographer}</span>
                </h2>
            ) : null}
          </div>
        </div>
      </div>
    </>
  )
}

export default ProjectTemplate